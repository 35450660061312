<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<minstmarcaje-modals
			:modelName="modelName"
		></minstmarcaje-modals>
	</div>
</template>

<script>
import minstmarcajeActions from './MinstmarcajeActions';
import minstmarcajeModals from './MinstmarcajeModals.vue';

export default {
	name: 'minstmarcaje-grid',
	components: {
		'minstmarcaje-modals': minstmarcajeModals
	},
	data() {
		return {
			modelName: 'minstmarcaje',
			actions: minstmarcajeActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
